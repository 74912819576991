<template>
    <div>
        <h1 class="heading">Сброс пароля</h1>

        <h6>Введите новый пароль</h6>
        <div class="custom-input-group">

            <custom-input-with-label
                :type="customInputToken.type"
                :name="customInputToken.name"
                :value="user.token"
                :text="customInputToken.text"
                v-on:change="setUserData('token', $event)"
            />

            <custom-input-password
                :name="customInputPassword.name"
                :value="user.password"
                :text="customInputPassword.text"
                v-on:change="setUserData('password', $event)"
            />

            <custom-input-password
                :name="customInputRepeatPassword.name"
                :value="user.repeatPassword"
                :text="customInputRepeatPassword.text"
                v-on:change="setUserData('repeatPassword', $event)"
            />
        </div>

        <main-button @click="resetPassword()">Отправить</main-button>

        <template v-for="(error, name) in errors" :key="name">
            <template v-for="(message, idx) in error" :key="idx">
                <notify-danger class="mt-2">{{ message }}</notify-danger>
            </template>
        </template>

        <template v-if="show && status.length > 0">
            <div class="result-message">
                <font-awesome-icon icon="info-circle" class="icon alt info-icon"/>
                {{ status }}
            </div>
        </template>

    </div>
</template>

<script>
export default {
    name: "ResetPassword",
    data() {
        return {
            user: {
                token: "",
                password: "",
                repeatPassword: "",
                email: ""
            },
            status: "",
            show: false,
            errors: [],
            customInputToken: {
                type: "hidden",
                name: "token",
                placeholder: "",
                text: "Секретный код"
            },
            customInputPassword: {
                type: "password",
                name: "password",
                placeholder: "",
                text: "Новый пароль"
            },
            customInputRepeatPassword: {
                type: "password",
                name: "repeatPassword",
                placeholder: "",
                text: "Повторите пароль"
            },
        }
    },
    methods: {
        setUserData(name, value) {
            if (value instanceof Event) return;

            this.user[name] = value;
        },
        resetPassword() {

            this.$store.dispatch('resetPassword', this.user).then((response) => {

                this.show = true;

                if (response.data && response.data['status']) {
                    this.status = response.data['status'];
                    setTimeout(() => {
                        this.$router.push({name: 'Login'})
                    }, 2000)
                }

            }).catch((reason) => {

                if (reason.response.data.error) {

                    //single mistake
                    this.errors[0] = [reason.response.data.error];

                } else if (reason.response.data.errors) {

                    //multiple mistakes
                    this.errors = reason.response.data.errors;

                } else if (reason.message) {

                    if (reason.message === 'Request failed with status code 403') {

                        //Case no verification or forbidden
                        this.errors[0] = ['Доступ запрещен'];

                    } else {

                        //Any other case
                        this.errors[0] = [reason.message];

                    }
                }

            });

        },
        getUrlParam(name) {
            let regEx = new RegExp('[?&]' + encodeURIComponent(name) + '=([^&]*)')
            let resEx = regEx.exec(window.location.hash);
            if (resEx)
                return decodeURIComponent(resEx[1]);
        },

    },
    mounted() {
        this.user.token = this.getUrlParam('token');
        this.user.email = this.getUrlParam('email');

    }
};
</script>

<style scoped>

.menu-link {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #71747A;
}

.menu-link:hover {
    text-decoration: none;
    color: #3663F2;
}

.result-message {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.info-icon {
    color: #3663F2;
    font-size: 32px;
    margin-right: 10px;
}

@media (max-width: 768px) {

}
</style>
